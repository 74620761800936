.container,
.nameEditor input,
.typeSelector {
  color: var(--color-green);

  &.readOnly,
  &[disabled] {
    border-bottom: 0;
    color: rgba(var(--color-grey-r), var(--color-grey-g), var(--color-grey-b), 0.8);
    cursor: not-allowed;
  }
}

.container {
  background: var(--color-light-green);
  border-radius: 5px;
  font-size: 0.7rem;
  display: inline;
  margin-right: 0.5rem;
  padding: 0.25rem;
  position: relative;

  &.readOnly {
    background: rgba(var(--color-light-grey-r), var(--color-light-grey-g), var(--color-light-grey-b), 0.3);
  }

  &:hover,
  &:focus-within {
    .deleteButton {
      display: block;
    }
  }

  input,
  select {
    cursor: pointer;

    &:focus {
      cursor: text;
    }
  }
}

.nameEditor input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--color-green);
  font-weight: bold;
}

.typeSelector {
  background: transparent;
  border: none;
}

.deleteButton {
  background: var(--color-white);
  border: 1px solid var(--color-grey);
  border-radius: 5px;
  color: var(--color-red);
  display: none;
  line-height: 1;
  padding: 3px;
  position: absolute;
  top: -10px;
  right: -10px;
}
