.sectionContainer {
  padding: 1.5em 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.toolbar {
  position: absolute;
  top: 10px;
  right: 10px;
}

.codeContainer {
  position: relative;
}

.codePreview {
  background-color: #e6e6e6;
  border: 1px solid #cacaca;
  margin: 0;
  height: calc(100vh - 3em);
  padding: 10px;
}
