.container {
  display: flex;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.deleteBtn {
  background-color: transparent;
  border: none;
  color: var(--color-red);
}

.arguments {
  list-style-type: none;
  padding: 0;
  white-space: nowrap;
}

.addArgument {
  background-color: transparent;
  border: 1px solid var(--color-light-grey);
  border-radius: 5px;
  color: var(--color-grey);
  font-size: 0.7rem;
  padding-top: 0.25rem;
}

.propertyName {
  margin-right: 0.5rem;

  input {
    border: 1px dashed var(--color-grey);
    border-radius: 5px;
    font-weight: bold;
    padding: 0 0.25rem;
  }

  @at-root {
    span#{&} {
      color: var(--color-grey);
      cursor: not-allowed;
      margin-left: 30px;
    }
  }
}
