:root {
  --color-grey-r: 116;
  --color-grey-g: 125;
  --color-grey-b: 133;

  --color-light-grey-r: 199;
  --color-light-grey-g: 199;
  --color-light-grey-b: 199;

  --color-grey: rgb(var(--color-grey-r), var(--color-grey-g), var(--color-grey-b));
  --color-light-grey: rgb(var(--color-light-grey-r), var(--color-light-grey-g), var(--color-light-grey-b));

  --color-green: #326b5a;
  --color-light-green: #cfe6e3;
  --color-red: #b03329;
  --color-white: #ffffff;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

label,
legend {
  color: var(--color-green);
  font-size: 1rem;
  font-weight: bold;
}

.form-check label {
  color: currentColor;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-green);
}

h1 {
  font-family: 'Bitter', sans-serif;
}

h2 {
  font-size: 1.25em;
}
