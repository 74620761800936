.container {
  border: 1px dashed var(--color-light-grey);
  font-family: var(--font-family-monospace);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.objectName input {
  border: 1px dashed var(--color-grey);
  border-radius: 5px;
  font-weight: bold;
  padding: 0 0.25rem;
}

.propertyList {
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
}

.addPropertyButton {
  background-color: transparent;
  border: 1px dashed var(--color-light-grey);
  color: var(--color-grey);
  font-size: 0.8rem;
  margin-left: 25px;
  padding: 0.5rem;
  text-align: center;
  width: calc(100% - 25px);
}

.deleteObjectButton {
  background-color: transparent;
  border: none;
  color: var(--color-red);
}
