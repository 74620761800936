.form {
  align-items: flex-end;
  display: flex;
  width: 100%;

  label {
    width: 100%;
  }

  input {
    width: 100%;
  }
}

.buttonContainer {
  padding-left: 10px;
}

.repeaterItems {
  border-bottom: 1px solid var(--color-light-green);
  list-style: none;
  margin-top: 1.5rem;
  padding-left: 0;
}

.repeaterItem {
  border-top: 1px solid var(--color-light-green);
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.repeaterContent {
  flex: 1;
}
