.header {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: var(--color-green);
  font-weight: bold;
  margin: 0;
  padding: 10px 0;
  text-align: left;
  width: 100%;
}

.icon {
  display: inline-block;
  text-align: center;
  width: 1.5em;
}

.body {
  padding: 15px 10px;
}

.header,
.body {
  border-bottom: 1px solid var(--color-light-green);
}
